import type { InferGetStaticPropsType } from "next";
import Head from "next/head";
import { useEffect, useState } from "react";
import { FeaturesCarousel } from "~/components/landing-page/Features";
import { Hero } from "~/components/landing-page/Hero";
import { HowItWorks } from "~/components/landing-page/how-it-works";
import { Header } from "~/components/landing-page/header";
import { OutfitDrawer } from "~/components/landing-page/outfit-drawer";
import { type Outfit, getOutfitsForLandingPage } from "~/lib/api";
import { cn } from "~/lib/utils";
import { env } from "~/env.mjs";
import { db } from "~/server/db";
import { useSearchParams } from "next/navigation";
import { FBQ } from "~/components/tracking-provider";

export default function Page({
  outfits,
}: InferGetStaticPropsType<typeof getStaticProps>) {
  const searchParams = useSearchParams();
  const source = searchParams?.get("source");
  if (source === "facebook") {
    FBQ.track("ViewContent", { content_ids: ["landing_page_view"] });
  }

  return (
    <>
      <Head>
        <meta name="theme-color" content="#ffffff" />
        <title>Glimpses - Look, Feel, and Dress Better</title>
      </Head>
      <Header />
      <Hero />
      <OutfitsGridSpotlights outfits={outfits} />
      <HowItWorks />
      <div
        id="features"
        className={cn(
          "sticky top-0 flex cursor-default flex-col items-center justify-center rounded-t-xl",
          "pb-24 pt-16 md:pb-36 md:pt-24",
          "dark bg-black text-foreground",
        )}
      >
        <FeaturesCarousel />
      </div>
      <OutfitDrawer outfits={outfits} />
    </>
  );
}

const OutfitsGridSpotlights = ({ outfits }: { outfits: Outfit[] }) => {
  const [activeIdx, setActiveIdx] = useState(0);

  useEffect(() => {
    const isSmall = window.matchMedia("(max-width: 768px)").matches;
    const interval = setInterval(() => {
      setActiveIdx((prev) => {
        const opts = outfits
          .slice(0, isSmall ? 6 : 10)
          .map((_, i) => i)
          .filter((i) => i !== prev);
        return opts[Math.floor(Math.random() * opts.length)]!;
      });
    }, 3000);

    return () => clearInterval(interval);
  });

  return (
    <div className="absolute left-0 top-24 -z-20 h-[75vh] w-full md:h-[70vh]">
      <div
        className={cn(
          "mx-auto h-full w-full max-w-[1600px]",
          "grid grid-cols-3 gap-3 lg:grid-cols-5",
          "px-4 md:px-12 lg:px-24",
        )}
      >
        {outfits.slice(0, 10).map((outfit, idx) => (
          <div
            key={outfit.id}
            className={cn(
              "relative h-full w-full overflow-hidden rounded-md bg-background",
              idx > 5 ? "hidden lg:block" : "block",
            )}
          >
            <img
              src={outfit.image_url}
              alt="Outfit"
              className={cn(
                "h-full w-full object-cover",
                "transition-opacity duration-1000 ease-in-out",
                idx === activeIdx ? "opacity-100" : "opacity-5",
              )}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export const getStaticProps = async () => {
  const outfits = await getOutfitsForLandingPage({
    page: 0,
    limit: 40,
    source: "generated",
    gender: "MALE",
  }).catch(async (err) => {
    if (env.NEXT_PUBLIC_ENV !== "production") {
      const outfits = await db.outfit.findMany({
        take: 20,
        where: { pipe_stage: "PROCESSED_PRODUCTS" },
        select: { id: true, original_image_url: true },
      });
      return {
        outfits: outfits.map((o) => ({
          id: o.id,
          image_url: o.original_image_url,
        })),
      };
    }
    throw err;
  });

  return {
    props: {
      outfits: outfits.outfits,
    },
    revalidate: 3600,
  };
};
